import { registerLocaleData } from '@angular/common';
import {
    HTTP_INTERCEPTORS,
    provideHttpClient,
    withInterceptorsFromDi,
} from '@angular/common/http';
import {
    APP_INITIALIZER,
    ApplicationConfig,
    LOCALE_ID,
    Provider,
    enableProdMode,
    importProvidersFrom,
    inject,
} from '@angular/core';
import { LuxonDateAdapter } from '@angular/material-luxon-adapter';
import {
    DateAdapter,
    ErrorStateMatcher,
    MAT_DATE_FORMATS,
    MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
    PreloadAllModules,
    provideRouter,
    withInMemoryScrolling,
    withPreloading,
} from '@angular/router';
import { provideFuse } from '@fuse';
import { TranslocoService, provideTransloco } from '@ngneat/transloco';
import { appRoutes } from 'app/app.routes';
import { provideAuth } from 'app/core/auth/auth.provider';
import { provideIcons } from 'app/core/icons/icons.provider';
import { mockApiServices } from 'app/mock-api';
import { firstValueFrom } from 'rxjs';
import { TranslocoHttpLoader } from './core/transloco/transloco.http-loader';

import { GlobalNavigationGuardService } from '#servicesv2/features/routing/global-config-routing-guard.service';
import { MyErrorStateMatcher } from '#utils/error-state-matcher/error-state-matcher';
import localeEn from '@angular/common/locales/en';
import localeEsHN from '@angular/common/locales/es-HN';
import * as Sentry from '@sentry/browser';
import { environment } from 'environments/environment';
import { CleanStringsInterceptor } from './http/interceptors/clean-strings.interceptor';
import { Interceptor } from './http/interceptors/http-interceptors.service';
import { LoadingInterceptor } from './http/interceptors/loading.interceptor';
import { SharedModule } from './shared/shared.module';

import { MyCustomPaginatorIntl } from '#utils/custom-paginator/custom-paginator';
import { registerLicense } from '@syncfusion/ej2-base';
import { Buffer } from 'buffer';
(window as any).Buffer = Buffer;

registerLocaleData(localeEsHN, 'es-HN');
registerLocaleData(localeEn, 'en');
registerLicense(environment.syncfusionKey);

if (environment.production) {
    enableProdMode();

    if (environment.sentryDSN.length) {
        Sentry.init({
            dsn: environment.sentryDSN,
            integrations: [
                Sentry.browserTracingIntegration(),
                Sentry.replayIntegration({
                    networkDetailAllowUrls: [
                        /https:\/\/api\.platinorepuestos\.com\/api\/([A-Za-z]+(\/[A-Za-z]+)+)/i,
                    ],
                }),
            ],
            tracesSampleRate: 1.0,
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,
            ignoreErrors: [
                'One or more validation errors occurred.',
                'Unknown Error.',
                'Unknown Error',
                'Usted no esta autorizado para ejecutar esta acción',
                'Error: Could not load "util".',
            ],
        });
    }
}

const providers: Provider[] = [
    {
        provide: MAT_DATE_LOCALE,
        useValue: 'es-HN',
    },
    {
        provide: LOCALE_ID,
        useValue: 'es-HN',
    },
    {
        provide: MatPaginatorIntl,
        useClass: MyCustomPaginatorIntl,
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: Interceptor,
        multi: true,
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: LoadingInterceptor,
        multi: true,
    },
    {
        provide: ErrorStateMatcher,
        useClass: MyErrorStateMatcher,
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: CleanStringsInterceptor,
        multi: true,
    },
];

//   if (environment.production && environment.sentryDSN.length) {
//     providers.push(
//       {
//         provide: ErrorHandler,
//         useValue: Sentry.createErrorHandler({
//           showDialog: false,
//         }),
//       },
//       {
//         provide: Sentry.TraceService,
//         deps: [Router],
//       },
//       {
//         provide: APP_INITIALIZER,
//         useFactory: () => () => {},
//         deps: [Sentry.Tra],
//         multi: true,
//       }
//     );
//   }

providers.push({
    provide: APP_INITIALIZER,
    useFactory:
        (globalNavigationGuardService: GlobalNavigationGuardService) => () =>
            globalNavigationGuardService.checkAccess('/'),
    deps: [GlobalNavigationGuardService],
    multi: true,
});

export const appConfig: ApplicationConfig = {
    providers: [
        importProvidersFrom(SharedModule),
        provideAnimations(),
        provideHttpClient(withInterceptorsFromDi()),
        provideRouter(
            appRoutes,
            withPreloading(PreloadAllModules),
            withInMemoryScrolling({ scrollPositionRestoration: 'enabled' })
        ),

        // Material Date Adapter
        {
            provide: DateAdapter,
            useClass: LuxonDateAdapter,
        },
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: 'D',
                },
                display: {
                    dateInput: 'DDD',
                    monthYearLabel: 'LLL yyyy',
                    dateA11yLabel: 'DD',
                    monthYearA11yLabel: 'LLLL yyyy',
                },
            },
        },

        // Transloco Config
        provideTransloco({
            config: {
                availableLangs: [
                    {
                        id: 'en',
                        label: 'English',
                    },
                    {
                        id: 'tr',
                        label: 'Turkish',
                    },
                ],
                defaultLang: 'en',
                fallbackLang: 'en',
                reRenderOnLangChange: true,
                prodMode: true,
            },
            loader: TranslocoHttpLoader,
        }),
        {
            // Preload the default language before the app starts to prevent empty/jumping content
            provide: APP_INITIALIZER,
            useFactory: () => {
                const translocoService = inject(TranslocoService);
                const defaultLang = translocoService.getDefaultLang();
                translocoService.setActiveLang(defaultLang);

                return () => firstValueFrom(translocoService.load(defaultLang));
            },
            multi: true,
        },

        // Fuse
        provideAuth(),
        provideIcons(),
        provideFuse({
            mockApi: {
                delay: 0,
                services: mockApiServices,
            },
            fuse: {
                layout: 'dense',
                scheme: 'light',
                screens: {
                    sm: '600px',
                    md: '960px',
                    lg: '1280px',
                    xl: '1440px',
                },
                theme: 'theme-brand',
                themes: [
                    {
                        id: 'theme-default',
                        name: 'Default',
                    },
                    {
                        id: 'theme-brand',
                        name: 'Brand',
                    },
                    {
                        id: 'theme-teal',
                        name: 'Teal',
                    },
                    {
                        id: 'theme-rose',
                        name: 'Rose',
                    },
                    {
                        id: 'theme-purple',
                        name: 'Purple',
                    },
                    {
                        id: 'theme-amber',
                        name: 'Amber',
                    },
                ],
            },
        }),
        ...providers,
    ],
};
