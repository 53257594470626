<img
    [matMenuTriggerFor]="userActions"
    class="h-auto w-8 cursor-pointer rounded-2xl"
    *ngIf="showAvatar && user?.avatar"
    [src]="user.avatar"
/>

<div class="flex">
    <mat-icon
        [matMenuTriggerFor]="userActions"
        class="mb-auto mt-auto h-full cursor-pointer"
        *ngIf="!showAvatar || !user.avatar"
        [svgIcon]="'heroicons_outline:user-circle'"
    ></mat-icon>
</div>

<mat-menu [xPosition]="'before'" #userActions="matMenu">
    <button mat-menu-item>
        <span class="flex flex-col space-y-1 leading-none">
            <span class="mt text-md font-medium">{{ user.name }}</span>
            <span class="mt text-md font-medium text-gray-400">{{
                user.email
            }}</span>
        </span>
    </button>
    <mat-divider class="my-2"></mat-divider>
    <button mat-menu-item (click)="navigateToUserSettings()">
        <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
        <span>Perfil</span>
    </button>
    <button mat-menu-item (click)="settings()">
        <mat-icon> settings </mat-icon>
        <span>Tema</span>
    </button>
    <mat-divider class="my-2"></mat-divider>
    <button mat-menu-item (click)="signOut()">
        <mat-icon>
            {{ 'logout' }}
        </mat-icon>
        <span>Cerrar Sesión</span>
    </button>
</mat-menu>
