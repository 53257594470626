import { Roles } from "#interfacesv2/api/user/enum/roles.enum";
import { ROUTEROLES } from "#utils/route-roles/route-roles";
import { Injectable } from "@angular/core";
import { ActivatedRoute, NavigationStart, Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class GlobalNavigationGuardService {
  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    // Escucha todos los eventos de navegación para depuración
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        const basePathNameOnly = event.url.split("?")[0];
        this.checkAccess(basePathNameOnly);
      }
    });
  }

  checkAccess(routeUrl: string): boolean {
    const role = localStorage.getItem("rol") as Roles;
    const routeRolesValue = ROUTEROLES?.[routeUrl as string];

    if (!routeRolesValue) {
      return true;
    }

    const routeRoles = routeRolesValue?.roles || [];

    if (routeRoles.length === 0) {
      return true;
    }

    if (routeRoles.includes(role)) {
      return true;
    } else {
      this.router.navigate(["/"]);
      return false;
    }
  }
}
