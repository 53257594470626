import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";

export class CleanStringsInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let modifiedReq = req.clone();

    // Filtrar el body en caso de POST, PUT o DELETE
    if ((req.method === "POST" || req.method === "PUT" || req.method === "DELETE") && req.body) {
      Object.keys(req.body).forEach((key) => {
        if (req.body[key] === "") {
          modifiedReq = modifiedReq.clone({
            body: { ...req.body, [key]: null },
          });
        }

        if (req.body[key] == undefined) {
          const { [key]: removed, ...bodyWithoutUndefined } = modifiedReq.body;
          modifiedReq = modifiedReq.clone({
            body: bodyWithoutUndefined,
          });
        }
      });
    }

    // Filtrar los queryParams en la URL
    if (req.params) {
      let params = req.params;
      params.keys().forEach((key) => {
        if (params.get(key) === null || params.get(key) === undefined) {
          params = params.delete(key);
        }
      });
      modifiedReq = modifiedReq.clone({
        params: params,
      });
    }

    return next.handle(modifiedReq);
  }
}
