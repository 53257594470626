import { AlertService } from '#utils/alert-service/alert-service.service';
import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { FuseAlertComponent } from '@fuse/components/alert';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [RouterOutlet, CommonModule, FuseAlertComponent],
})
export class AppComponent {
    alertService = inject(AlertService);

    constructor() {}

    errorMessage$: Observable<{
        message: string;
        title?: string;
        errors: Array<string> | null;
    }> = this.alertService.message$;
    updateMessage$: Observable<string> = this.alertService.updateMessage$;
    saveMessage$: Observable<string> = this.alertService.saveMessage$;
}
