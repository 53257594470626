import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '#root/core/auth/auth.service';
import { AuthUtils } from '#root/core/auth/auth.utils';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class Interceptor implements HttpInterceptor {
    constructor(
        private router: Router,
        private authService: AuthService
    ) {}

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        if (AuthUtils.isTokenExpired(this.authService.accessToken)) {
            this.authService.signOut();
            return next.handle(req);
        }

        return next
            .handle(req)
            .pipe(catchError((err) => this.managementError(err)));
    }

    managementError(err: HttpErrorResponse) {
        if (err.status === 404) {
            this.router.navigate(['error/404'], { skipLocationChange: true });
            return throwError(err);
        }
        if (err.status === 500) {
            this.router.navigate(['error/500'], { skipLocationChange: true });
            return throwError(err);
        }
        if (err.status === 401) {
            this.router.navigate(['/error/403']);
            return throwError(err);
        }
        if (err.status === 403) {
            this.router.navigate(['error/403'], { skipLocationChange: true });
            return throwError(err);
        }

        if (err.status === 0) {
            err.error.Message = err.message;
        }

        return throwError(err);
    }
}
