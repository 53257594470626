<router-outlet></router-outlet>
<div class="fixed right-[4%] top-[4%] z-99999">
    <fuse-alert
        *ngIf="updateMessage$ | async as updateMessage"
        [type]="'success'"
        [appearance]="'outline'"
        [dismissible]="true"
        [dismissed]="true"
        [name]="'update-alert'"
        class="relative"
    >
        <span fuseAlertTitle>Exito</span>
        {{ updateMessage }}
    </fuse-alert>
    <fuse-alert
        *ngIf="saveMessage$ | async as saveMessage"
        [type]="'success'"
        [appearance]="'outline'"
        [dismissible]="true"
        [dismissed]="true"
        [name]="'save-alert'"
        class="relative"
    >
        <span fuseAlertTitle>Exito</span>
        {{ saveMessage }}
    </fuse-alert>
    <fuse-alert
        [type]="'error'"
        *ngIf="errorMessage$ | async as errorMessage"
        [appearance]="'outline'"
        [dismissible]="true"
        [dismissed]="true"
        [name]="'error-alert'"
        class="relative"
    >
        <span fuseAlertTitle>{{
            errorMessage?.message ?? errorMessage.title
        }}</span>
        <p *ngFor="let error of errorMessage.errors">{{ error }}</p>
    </fuse-alert>
</div>
